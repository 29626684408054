<template>
	<transition name="el-fade-in-linear">
		<div id="addImageFileBaseFormBg" class="fade" v-if="isShow" v-loading="loading">
			<div id="form">
				<div id="head">上传图片 <div id="close" class="po sc hand" @click="onClose">X</div> </div>
				<div id="center">

<!--					<el-upload class="upload op sc" drag multiple :auto-upload="false" action=" " :show-file-list="false" :on-change="uploadImg" accept="image/*">-->
<!--						<div class="el-upload__text">拖拽图片到这里，或<em>点此添加</em></div>-->
<!--						<p>请您确保上传的内容符合法律法规，符合公共道德</p>-->
<!--					</el-upload>-->
                    <div style="width: 100%;height: 120px;text-align: center;">
                        <el-upload class="upload op sc" drag multiple :auto-upload="false" action=" " :show-file-list="false" :on-change="uploadImg" accept="image/*">
                            <div>拖拽图片到这里，或<em style="color: #aa7700;">点此添加</em> </div>
                        </el-upload>
                    </div>
                    <div v-show="imgList.length == 0" style="width: 100%;height: auto;text-align: center;margin-top: 20px;">
                        <div>扫描二维码手机上传文件</div>
                        <div  style="margin: 0 auto;">
                            <vue-qr
                                :text="qr_imgUrl + 'uploadImages?uuid=' + user.uuid + '&type=10' + '&sign=' + sign"
                                :size="250"
                                :logoSrc="qr_logo"
                                :logoScale="0.2">
                            </vue-qr>
                        </div>
                    </div>

                    <div v-show="imgList.length > 0" style="width: 100%;line-height: 30px;text-align: center;margin-top: 10px;">
                        <div>扫描二维码手机上传文件<em @click="isCodeFile = true"  style="color: #aa7700;">点此添加</em> </div>
                        <el-dialog  :visible.sync="isCodeFile" width="300px">
                            <vue-qr
                                :text="qr_imgUrl + 'uploadImages?uuid=' + user.uuid + '&type=7' + '&sign=' + sign"
                                :size="250"
                                :logoSrc="qr_logo"
                                :logoScale="0.2">
                            </vue-qr>
                        </el-dialog>
                    </div>

					<div id="panel" v-show="imgList.length > 0">
						<div id="panelLeft">

							<div class="formItem">
								<div class="label"><em>*</em> 图片标题</div>
								<el-input class="input" v-model="title" placeholder="请输入图片标题"></el-input>
							</div>

							<div id="imgList">
								<div class="imgFile" v-for="(item,index) in imgList" :class="{'active':item === activeImg}">
									<img v-if="item.url !== ''" :src="item.url" class="op sc hand" @click="activeImg = item" >
									<div class="progress" v-if="item.url === ''">
										<el-progress :percentage="item.progress" :show-text="false"></el-progress>
										<p>{{item.progress}}%</p>
									</div>
									<div class="isCoverImage" v-if="item.isCoverImage">封面</div>
									<div class="describe op sc hand" @click="activeImg = item" v-if="item.describe">{{item.describe}}</div>
									<div class="remove op sc hand" @click="imgList.splice(index,1)">x</div>
								</div>

							</div>
						</div>
						<div id="panelRight">
							<img id="preViewImg" :src="activeImg.url">
							<el-form>
								<el-form-item label="图片描述">
									<el-input type="textarea" :rows="5" v-model="activeImg.describe"></el-input>
								</el-form-item>

								<el-form-item label="设为封面">
									<el-switch v-model="activeImg.isCoverImage" @change="coverImageChange"></el-switch>
								</el-form-item>
							</el-form>
						</div>
					</div>
				</div>

				<div id="bottom" style="float:right;margin-right: 15px;">
					<label >
						<el-button type="primary" icon="el-icon-position" class="button op sc hand" @click="submit">{{ editId == 0 ? '发布' : '保存'  }}</el-button>
					</label>

				</div>
			</div>

		</div>
	</transition>
</template>

<script>


	export default {
		name: "addImageFileBaseForm",
		data(){
			return{
				title:"",
				content:"",
				activeImg:{},
				loading:false,
				imgList:[],
                //扫码上传文件
                isCodeFile:false,
                qr_imgUrl:this.$store.state.mobileUrl,
                qr_logo: require('@/assets/imgs/other/bgb.png'),
                sign:this.utils.random_string(6),
			}
		},
        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },
        },
		props:{
			roomId:{
				type:Number,
				default(){
					return 0;
				}
			},
			menuId:{
				type:Number,
				default(){
					return 0;
				}
			},
			editId:{
				type:Number,
				default(){
					return 0;
				}
			},
			isShow:{
				type:Boolean,
				default(){
					return false;
				}
			},
			onClose:{
				type:Function,
				default(){

				}
			}

		},
		mounted() {
            var that = this;
			// 图片详情
			if(this.editId > 0) {
				this.getRoomContentInfo();
			}

            this.$EventBus.$EventBus.$off("callbackNotice");
            this.$EventBus.$on("callbackNotice", (data) => {
                this.$nextTick(() => {
                    if (data.code == 100) {
                        let obj = JSON.parse(data.data);
                        if(obj.type == 1001){
                            that.getCodeFiles(obj);
                        }else if(obj.type == 1002){
                            that.delCodeFiles(obj);
                        }
                    }
                })
            })

		},
		methods: {
			getRoomContentInfo: function () {
				var that = this;
				this.newApi.getRoomContentInfo({
					id: that.editId
				}).then((res) => {
					that.imgList = res.data.content.images;
					for (let i = 0;i < that.imgList.length; i ++){
						if(that.imgList[i].isCoverImage == true){
							that.activeImg = {
								progress:0,
								isCoverImage:true,
								url:that.imgList[i].url,
								describe:that.imgList[i].describe
							};
						}
					}
					that.title = res.data.content.content;
				}).catch((err) => {
					console.log(err)
				})
			},

            getCodeFiles(obj){
                var that = this;
                const id = that.utils.random_string();
                let param = {
                    id:id,
                    progress:0,
                    isCoverImage:true,
                    url:"",
                    describe:"",
                };
                if(this.imgList.length > 0){
                    param.isCoverImage = false;
                }
                this.activeImg = param;
                this.imgList.push(this.activeImg);
                //预载一下图片防止加载不丝滑
                const img = new Image();
                img.src = obj.url;
                img.onload = function (){
                    that.setUrl(id,obj.url,obj.hash);
                }
            },

            delCodeFiles(obj){
                var that = this;
                for (let i = 0; i < that.imgList.length; i++) {
                    if(that.imgList[i].hash == obj.hash){
                        that.imgList.splice(i,1);
                        that.utils.sus("删除成功");
                        break;
                    }
                }
            },

			uploadImg(file){
				const that = this;
				const id = this.utils.random_string();
				let param = {
					id:id,
					progress:0,
					isCoverImage:true,
					url:"",
					describe:"",
				};
				if(this.imgList.length > 0){
					param.isCoverImage = false;
				}
				this.activeImg = param;
				this.imgList.push(this.activeImg);
				this.utils.upload(file.raw,function (ret){
					if (!ret){
						return false;
					}
					//预载一下图片防止加载不丝滑
					const img = new Image();
					img.src = ret.url;
					img.onload = function (){
						that.setUrl(id,ret.url,ret.hash);
					}

				},function (p){
					that.setProgress(id,p)
				})
			},

            setUrl(id,url,hash){
                var that = this;
                let having = this.imgList.some(item=>{
                    return item.url==url
                })
                if (!having){
                    for (let i = 0; i < this.imgList.length; i++) {
                        if (this.imgList[i].id === id){
                            this.imgList[i].url = url;
                            this.imgList[i].hash = hash;
                            that.utils.sus("上传成功");
                        }
                    }
                }else {
                    for (let i = 0; i < this.imgList.length; i++) {
                        if (that.imgList[i].id === id){
                            that.imgList.splice(i,1);
                            this.activeImg = that.imgList[i-1];
                            that.utils.err("图片已存在");
                        }
                    }

                }

            },
			setProgress(id,progress){
				for (let i = 0; i < this.imgList.length; i++) {
					if (this.imgList[i].id === id){
						this.imgList[i].progress = progress;
					}
				}
			},
			coverImageChange(val){
				if(this.imgList.length == 1){
					this.utils.msg("最少要有一张封面");
					return false;
				}
				for (let i = 0; i < this.imgList.length; i++) {
					this.imgList[i].isCoverImage = false;
					if(this.imgList[i].url == this.activeImg.url){
						this.imgList[i].isCoverImage = true;
					}
				}
				this.activeImg.isCoverImage  = true;
				console.log(this.imgList)
			},
			submit(){
				const that = this;
				if (this.imgList.length == 0){
					this.utils.err("请上传至少一张图片");return;
				}

				if (this.title == ""){
					this.utils.err("请填写标题");return;
				}

				const params = {};
				let content = {};
				content.content = this.title;
				content.enclosure = [];
				content.videos = [];

				this.imgList.forEach((item,index)=>{
					this.imgList[index] = {
						url:item.url,
						hash:item.hash,
						describe:item.describe,
						isCoverImage:item.isCoverImage,
					}
					if(item.url == that.activeImg.url){
						this.imgList[index].describe = that.activeImg.describe;
					}
				})
				content.images = this.imgList;
				content.bgbType = 7;

				params.roomId = that.roomId;
				params.menuId = that.menuId;
				params.data = "{}";
				params.content = JSON.stringify(content);
				console.log(params);

				this.loading = true;
				if (this.editId == 0){
					that.openLoading('正在发布请稍后');
					this.newApi.addRoomTemplateContent(params).then((ret)=>{
						if(ret.isSuccess == 1){
							that.utils.sus(ret.data);
							this.title = "";
							that.imgList = [];
							that.contentImages();
						}
					}).catch((err)=>{
						console.log(err)
					}).finally(()=>{
						that.loading = false;
					})
				}else{
					that.openLoading('正在发布请稍后');
					params.id = this.editId;
					this.newApi.editTemplateContent(params).then((ret)=>{
						if(ret.isSuccess == 1){
							that.utils.sus(ret.data);
							that.contentImages();
						}
					}).catch((err)=>{
						console.log(err)
					}).finally(()=>{
						that.loading = false;
					})
				}
			},
			contentImages(){
				this.onClose();
				this.$EventBus.$emit("roomContentFresh", {menuId: this.menuId});
			},
		},
	}
</script>

<style >
.v-modal{
    z-index: 0 !important;
}


</style>
