import { render, staticRenderFns } from "./release.vue?vue&type=template&id=797963ba&scoped=true"
import script from "./release.vue?vue&type=script&lang=js"
export * from "./release.vue?vue&type=script&lang=js"
import style0 from "./release.vue?vue&type=style&index=0&id=797963ba&prod&lang=css"
import style1 from "./release.vue?vue&type=style&index=1&id=797963ba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "797963ba",
  null
  
)

export default component.exports